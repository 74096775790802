/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { Link, navigate } from "gatsby";
// import Helmet from 'react-helmet';
// import firebase from 'gatsby-plugin-firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faBookOpen,
  faEnvelope,
  faHome,
  faTags,
  faAddressCard,
  faMagnifyingGlass,
  faCode,
} from "@fortawesome/free-solid-svg-icons";
import tw, { css } from "twin.macro";
import MobileMenu from "../drawer";

// import './style.scss';
import { logout } from "../utils/auth";
import { useTheme } from "../../layout_emotion/themeContext";

const dark = (theme) => [
  css`
    background-color: ${theme.background};
    border-color: ${theme.color};
    color: ${theme.color};
    &:hover {
      color: blue;
    }
  `,
];

const NavList = (props) => {
  // const overflowHidden = navStyle.includes('open');
  const iconList = new Array();
  iconList.push({
    key: "Home",
    icon: faHome,
    url: "/",
    target: "",
    rel: "",
  });
  iconList.push({
    key: "All Articles",
    icon: faBookOpen,
    url: "/blog/",
    target: "",
    rel: "",
  });
  iconList.push({
    key: "About",
    icon: faAddressCard,
    url: "/about/",
    target: "",
    rel: "",
  });
  iconList.push({
    key: "Mail",
    icon: faEnvelope,
    url: "/mail/",
    target: "",
    rel: "",
  });
  iconList.push({
    key: "CodeSandbox",
    icon: faCode,
    url: "https://codesandbox.io/u/fangjoker.tech4312",
    target: "_blank",
    rel: "nofollow noreferrer",
  });
  // iconList.push({ key: 'arechives', icon: faBookOpen, url: '/home' });
  iconList.push({
    key: "Search",
    icon: faMagnifyingGlass,
    url: "/algoliaSearch/",
    target: "",
    rel: "",
  });
  // iconList.push({ key: 'logout', icon: faSignOutAlt, url: '/' });

  return (
    <nav {...props}>
      <div css={[navStyles.container]}>
        <div
          css={[
            css`
              margin-right: 30px;
            `,
          ]}
        >
          <Link
            to="/blog/"
            css={[
              css`
                font-family: Meiryo;
                margin-left: 10px;
                font-weight: bold;
                text-decoration: none;
                color: #f39c12;
              `,
            ]}
          >
            SCRAWLED TECH BLOG
          </Link>
        </div>
        {iconList.map((iconObj) => {
          return (
            <div
              key={iconObj.key}
              css={[
                tw`flex-grow`,
                css`
                  margin-left: 10px;
                `,
              ]}
            >
              <Link
                to={iconObj.url}
                key={iconObj.url}
                target={iconObj.target}
                rel={iconObj.rel}
                activeClassName="active"
                css={[
                  css`
                    text-decoration: none;
                    color: inherit;
                  `,
                ]}
                onClick={(event) => {
                  if (iconObj.key === "logout") {
                    event.preventDefault();
                    logout(firebase).then(() => navigate(`/`));
                  }
                }}
              >
                <div css={[tw`flex justify-center gap-2 items-center`]}>
                  <div css={[tw`flex justify-center`, dark]}>
                    <FontAwesomeIcon icon={iconObj.icon} />
                  </div>
                  <div css={[tw`flex justify-center`, dark]}>
                    <span>{iconObj.key}</span>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </nav>
  );
};

const navStyles = {
  container: () => [
    css`
      display: flex;
      justify-content: space-between;
      gap: 10px;
    `,
  ],
};

const Header = () => {
  // if (pathname === '/') {
  //   return <></>;
  // }

  const [isOpenMenu, toggleOpenMenu] = useState(false);
  // useContext取得
  const { colorMode, setColorMode } = useTheme();
  // toggleの状態
  const [mode, changeMode] = useState(() => colorMode !== "light");

  const modeChanging = () => {
    setColorMode();
    changeMode(!mode);
  };

  // toggleスタイル
  const chenge = (flg) => {
    if (!flg) {
      return [
        tw`absolute left-1 top-1 bg-gray-400 w-6 h-6 rounded-full transition`,
      ];
    }
    return [
      css`
        transform: translateX(100%);
      `,
      tw`absolute left-1 top-1 bg-green-400 w-6 h-6 rounded-full transition`,
    ];
  };

  return (
    <header>
      <div
        css={[
          headerStyles.container,
          (theme) => [
            css`
              box-shadow: 0 0.25rem 1.25rem ${theme.color};
              background-color: ${theme.background};
            `,
          ],
        ]}
      >
        <NavList
          css={[
            headerStyles.container,
            headerStyles.item(3),
            css`
              justify-content: flex-start;
              @media (max-width: 800px) {
                display: none;
              }
            `,
          ]}
        />
        <Link
          to="/blog/"
          css={[
            css`
              font-family: Meiryo;
              margin-left: 10px;
              font-weight: bold;
              text-decoration: none;
              color: #f39c12;
              @media (min-width: 800px) {
                display: none;
              }
            `,
          ]}
        >
          SCRAWLED TECH BLOG
        </Link>

        <div
          css={[
            headerStyles.container,
            headerStyles.item(1),
            css`
              justify-content: flex-end;
            `,
          ]}
        >
          <div
            css={[
              css`
                @media (min-width: 800px) {
                  display: none;
                }
              `,
            ]}
          >
            <MobileMenu />
          </div>
          <label htmlFor="toggleB" css={[tw`cursor-pointer`]}>
            <div css={[tw`relative`]}>
              <input
                id="toggleB"
                type="checkbox"
                css={tw`sr-only`}
                onClick={modeChanging}
              />
              {/* トグル */}
              <div css={[tw`block bg-gray-600 w-14 h-8 rounded-full`]} />
              {/* 丸 */}
              <div css={[chenge(mode)]} />
            </div>
          </label>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);

const headerStyles = {
  container: () => [
    css`
      display: flex;
      gap: 10px;
      align-items: center;
      height: 55px;
    `,
  ],
  item: (flex) => [
    css`
      flex: ${flex};
    `,
  ],
};
